<template>
  <div class="pt-5 px-7">
    <div class="mb-4">
      <UploadFile multiple :drag="true" @on-success="onSuccessUploadFile">
        <div class="text-info">
          <UploadIcon width="50px" height="50px" />
        </div>
        <div class="el-upload__text">
          {{ $t('document.documentsOverview.classificationTasks.uploadFile.dropText') }}
          <em>
            {{ $t('document.documentsOverview.classificationTasks.uploadFile.clickText') }}
          </em>
        </div>
      </UploadFile>

      <p class="text-center text-muted mt-2">
        {{ $t('document.documentsOverview.classificationTasks.uploadFile.supportedFiles') }}
      </p>
    </div>
    <div class="row">
      <div :style="{ width: '45%' }">
        <div class="d-flex justify-content-between mb-4">
          <h3>
            {{ $tc('document.documentsOverview.organizationTasks.title') }}
          </h3>
          <el-dropdown v-if="selectedDocIds.length" trigger="click" @command="(method) => _self[method]()">
            <Button>
              {{
                $t('document.documentsOverview.classificationTasks.table.selectedDocIds', {
                  count: selectedDocIds.length,
                })
              }}
              <i class="el-icon-arrow-down el-icon--right" />
            </Button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="approveDocs">
                <div class="d-flex align-items-center gap-2" :class="'text-typography-primary'">
                  <CheckIcon />
                  <p>{{ $t('document.documentsOverview.classificationTasks.command.approveSelected') }}</p>
                </div>
              </el-dropdown-item>
              <el-dropdown-item command="mergeDocs">
                <div
                  class="d-flex align-items-center gap-2 text-typography-primary"
                  :class="text - typography - primary"
                >
                  <MergeDocumentIcon />
                  <p>
                    {{ $t('document.documentsOverview.classificationTasks.table.merge') }}
                    <span class="text-muted">
                      {{
                        $t('document.documentsOverview.classificationTasks.table.mergeOrder', {
                          order: selectedDocOrder.join(','),
                        })
                      }}
                    </span>
                  </p>
                </div>
              </el-dropdown-item>
              <el-dropdown-item command="removeDocs">
                <div class="d-flex align-items-center gap-2 text-danger">
                  <TrashCanIcon />
                  <p>
                    {{ $t('document.documentsOverview.classificationTasks.command.removeSelected') }}
                  </p>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-form>
          <Table
            :columns="columns"
            :data="taskedDocuments"
            :row-selection.sync="rowSelection"
            show-index
            :expandable.sync="expandableRows"
            rounded
            border
            :cell-class="selectedRow"
            class="scrollable-table"
            custom-class="organize-tasks-table"
            @row-click="handleRowClick(taskedDocuments[$event])"
          >
            <template #cell-file="{ rowData: { doc, task } }">
              <TruncatedText>
                {{ getFileName(doc) }}
              </TruncatedText>
              <small class="date text-muted mx-1">
                {{ formatDate(task.createdAt) }}
              </small>
            </template>
            <template #cell-uploadedBy="{ rowData: { doc, task } }">
              <TruncatedText>
                <template v-if="task.data.fromEmail">{{ task.data.fromEmail }}</template>
                <template v-else-if="doc.fromEmail"> {{ doc.fromEmail }}</template>
                <template v-else> {{ getUserName(doc.createdBy) }}</template>
              </TruncatedText>
            </template>
            <template #cell-pageCount="{ rowData: { doc } }">
              <TruncatedText>
                <template v-if="!isNil(doc.pageCount)">{{ doc.pageCount }}</template>
                <template v-else> - </template>
              </TruncatedText>
            </template>
            <template #cell-approve="{ rowData: { doc, task }, rowIndex }">
              <Button
                v-if="showApprove(rowIndex)"
                type="secondary"
                :disabled="disableOperations(doc)"
                @click="handleRowAction('approveDoc', doc, task, rowIndex)"
              >
                {{ $t('document.documentsOverview.organizationTasks.table.approveAsDocument') }}
              </Button>
            </template>
            <template #cell-actions="{ rowData: { doc, task }, rowIndex }">
              <div class="d-flex">
                <el-dropdown
                  class="d-flex justify-content-center"
                  placement="bottom"
                  trigger="click"
                  @command="(command) => handleRowAction(command, doc, task, rowIndex)"
                >
                  <Button type="text" class="p-0 action-btn actions-icon">
                    <KebabIcon class="text-typography-primary" />
                  </Button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="approveDoc">
                      <div
                        v-if="!disableOperations(doc)"
                        class="d-flex align-items-center gap-2"
                        :class="`${disableOperations(doc) ? 'text-disabled' : 'text-typography-primary'}`"
                      >
                        <CheckIcon />
                        <p>{{ $t('document.documentsOverview.classificationTasks.command.approve') }}</p>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item
                      :divided="!disableOperations(doc)"
                      command="rotateDoc"
                      :disabled="disableOperations(doc)"
                    >
                      <div
                        class="d-flex align-items-center gap-2"
                        :class="`${disableOperations(doc) ? 'text-disabled' : 'text-typography-primary'}`"
                      >
                        <RotateDocumentIcon />
                        <p>{{ $t('document.documentsOverview.classificationTasks.command.rotate') }}</p>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item command="splitDoc" :disabled="disableOperations(doc)">
                      <div
                        class="d-flex align-items-center gap-2"
                        :class="`${disableOperations(doc) ? 'text-disabled' : 'text-typography-primary'}`"
                      >
                        <SplitDocumentIcon />
                        <p>{{ $t('document.documentsOverview.classificationTasks.command.split') }}</p>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item command="duplicateDoc" :disabled="disableOperations(doc) || task.reviewRequired">
                      <div
                        class="d-flex align-items-center gap-2"
                        :class="`${
                          task.reviewRequired || disableOperations(doc) ? 'text-disabled' : 'text-typography-primary'
                        }`"
                      >
                        <CopyIcon />
                        <p>{{ $t('document.documentsOverview.classificationTasks.command.duplicate') }}</p>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item divided command="downloadDoc">
                      <div class="d-flex align-items-center gap-2 text-typography-primary">
                        <DownloadIcon />
                        <p>{{ $t('document.documentsOverview.classificationTasks.command.download') }}</p>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item divided command="removeDoc">
                      <div class="d-flex align-items-center gap-2">
                        <TrashCanIcon />
                        <p>
                          {{ $t('document.documentsOverview.classificationTasks.command.remove') }}
                        </p>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
            <template #expandable-content="{ rowData: { doc } }">
              <DocumentInfo
                :document="doc"
                @pageClicked="handlePageViewerClick"
                @approveSinglePage="handleApproveSingle"
              />
            </template>
          </Table>
        </el-form>
      </div>
      <DocumentModal
        v-if="existingDocumentId"
        visible
        :document-id="existingDocumentId"
        @close="existingDocumentId = null"
      />

      <div class="col">
        <h3 class="mb-4">
          {{ $tc('document.documentsOverview.organizationTasks.previewTitle') }}
        </h3>
        <div v-if="!togglePageViewer && previewedDoc" class="viewer-container">
          <file-viewer v-if="previewedDoc" :url="previewedDoc.filePathUrl" />
        </div>

        <div v-if="togglePageViewer && previewedDoc" class="viewer-container overflow-auto">
          <SinglePagePdfViewer :src="previewedDoc.filePathUrl" :page.sync="pageViewerNumber"> </SinglePagePdfViewer>
        </div>

        <div v-if="!previewedDoc" class="no-file-selected me-3 viewer-container">
          {{ $t('document.documentsOverview.organizationTasks.noFileSelected') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isNil, isEmpty, anyPass, reject } from 'ramda';
import { ref, computed } from 'vue';

import {
  UploadIcon,
  KebabIcon,
  CopyIcon,
  SplitDocumentIcon,
  MergeDocumentIcon,
  RotateDocumentIcon,
  DownloadIcon,
  CheckIcon,
  TrashCanIcon,
} from '@/assets/icons';
import { options } from '@/locale/dateConfig';
import { FileViewer } from '@clarityo/ui-components';
import { convertDocument } from '@/modules/document/compositions/documents-queries';
import { DocumentModal } from '@/modules/documentModal';
import { Button, Table, TruncatedText } from '@/modules/core';
import { useCompleteTaskNew2, usePatchTaskNew2 } from '@/modules/tasks';
import { useTenancy } from '@/modules/auth';
import {
  updateDocument,
  deleteDocument,
  splitDocument,
  mergeDocuments,
  deriveDocuments,
  duplicateDocument,
  rotateDocument,
} from '@/modules/document/compositions/documents-operations';

import { useDocumentTasks, useClassificationDocuments } from '@/modules/document/compositions';
import { UploadFile, DocumentInfo, SinglePagePdfViewer } from '@/modules/document/components';
import { useUsers } from '../payment/compositions';
import activityTrackEvents from '@/analytics/activity-track';
import { ANALYTICS_EVENT_TYPES } from '@/analytics/types';
import { ANALYTICS_ENUMS } from './utils/analytics';

const TABLE_HEADERS = {
  FILE: 'file',
  UPLOADED_BY: 'uploadedBy',
  PAGE_COUNT: 'pageCount',
  APPROVE: 'approve',
};

export default {
  meteor: {},
  components: {
    FileViewer,
    UploadIcon,
    KebabIcon,
    Button,
    Table,
    DocumentModal,
    TruncatedText,
    CopyIcon,
    SplitDocumentIcon,
    MergeDocumentIcon,
    DownloadIcon,
    RotateDocumentIcon,
    CheckIcon,
    TrashCanIcon,
    UploadFile,
    DocumentInfo,
    SinglePagePdfViewer,
  },
  setup() {
    const { currentTenantId } = useTenancy();
    const { documentTasks, refetch: refetchTasks } = useDocumentTasks({
      type: 'organize',
      businessId: currentTenantId.value,
    });
    const { patchTask } = usePatchTaskNew2();
    const businessId = computed(() => currentTenantId.value);
    const { documents, refetch: refetchDocuments } = useClassificationDocuments(businessId);
    const { completeTask } = useCompleteTaskNew2();
    const removedMemoryTasks = ref([]);
    const organizationTasks = computed(() =>
      documentTasks.value.filter((task) => task.type === 'organize' && !removedMemoryTasks.value.includes(task.id))
    );
    const managerReviewCommentVisible = ref({});
    const { users } = useUsers(
      computed(() => documents.value.filter((doc) => doc.createdBy !== null).map((doc) => doc.createdBy))
    );

    activityTrackEvents(ANALYTICS_EVENT_TYPES.PAGE.ACTIVITY, {
      domain: ANALYTICS_ENUMS.DOMAIN,
      source_page: ANALYTICS_ENUMS.SOURCE_PAGE.ORGANIZATION,
    });

    return {
      removedMemoryTasks,
      organizationTasks,
      unrecordedDocuments: computed(() => documents.value.map(convertDocument)),
      users: computed(() => [...Object.values(users.value)]),
      refetchTasks,
      refetchDocuments,
      completeTask,
      businessId,
      patchTask,
      managerReviewCommentVisible,
      isNil,
    };
  },
  data() {
    const taskSelection = Number(this.$route.query.taskNumber);
    return {
      commentReviewModalOpen: false,
      selectedCommentTask: null,
      existingDocumentIdByIndex: {},
      previewIndex: Number.isNaN(taskSelection) ? 0 : taskSelection - 1,
      rowSelection: [],
      expandableRows: ref({}),
      selectedDocIds: [],
      existingDocumentId: null,
      tableInputState: [],
      togglePageViewer: false, // toggle between page viewer or batch viewer
      pageViewerNumber: null,
      nextPageViewerNumber: null,
      pages: {},
    };
  },
  computed: {
    isDataReady() {
      return Object.keys(this.$subReady).reduce((ready, key) => this.$subReady[key] && ready, true);
    },
    columns() {
      return [
        {
          header: this.$tc('document.documentsOverview.organizationTasks.table.file'),
          key: TABLE_HEADERS.FILE,
        },
        {
          header: this.$t('document.documentsOverview.organizationTasks.table.uploadedBy'),
          key: TABLE_HEADERS.UPLOADED_BY,
        },
        {
          header: this.$t('document.documentsOverview.organizationTasks.table.pageCount'),
          key: TABLE_HEADERS.PAGE_COUNT,
        },
        {
          key: TABLE_HEADERS.APPROVE,
          width: '126px',
        },
        { key: 'actions', width: '48px' },
      ];
    },
    previewedDoc() {
      const { doc } = this.taskedDocuments[this.previewIndex] || {};
      return doc;
    },
    taskedDocuments() {
      return (this.organizationTasks || [])
        .map((task) => {
          const documentId = task.data.documentId;
          const document = (this.unrecordedDocuments || []).find((unrecordedDoc) => unrecordedDoc.id === documentId);
          return { doc: document, task, id: task.id, expandable: true, expandableCustomClass: 'p-0' };
        })
        .filter(({ doc }) => doc)
        .sort((a, b) => {
          return Math.abs(a.doc.createdAt.getTime() - b.doc.createdAt.getTime()) < 1000
            ? this.getFileNameIndex(a.doc) - this.getFileNameIndex(b.doc)
            : a.doc.createdAt - b.doc.createdAt;
        });
    },
    selectedDocOrder() {
      return this.selectedDocIds.map((id) => {
        return this.taskedDocuments.findIndex(({ doc }) => doc.id === id) + 1;
      });
    },
  },

  watch: {
    rowSelection(selected) {
      if (selected) {
        this.selectedDocIds = selected.map((index) => this.taskedDocuments[index].doc.id);

        if (selected.length) {
          this.previewIndex = selected[selected.length - 1];
        }

        // showing not selected document when all of the checkboxes are checked
        if (selected.length === this.taskedDocuments.length) {
          this.previewIndex = null;
        }
      }
    },
    previewIndex(index) {
      this.$router.replace({ query: { taskNumber: index + 1 } });
    },
  },

  methods: {
    disableOperations(doc) {
      return !this.isDocumentContainPages(doc);
    },
    showApprove(rowIndex) {
      return (this.previewIndex === rowIndex || this.rowSelection.includes(rowIndex)) && !this.togglePageViewer;
    },
    isDocumentContainPages(doc) {
      return isNil(doc?.pageCount) ? true : doc?.pageCount > 0;
    },
    async onSuccessUploadFile() {
      await this.refetchData({ shouldClearSelection: false });
    },
    async handleRowAction(command, document, task) {
      switch (command) {
        case 'approveDoc':
          this.approveDoc({ taskId: task.id });
          break;
        case 'splitDoc':
          this.splitDoc(document);
          break;
        case 'duplicateDoc':
          this.duplicateDoc(document);
          break;
        case 'rotateDoc':
          this.rotateDoc(document);
          break;
        case 'downloadDoc':
          this.downloadDoc(document);
          break;
        case 'removeDoc':
          this.removeDoc(document);
          break;
        default:
          break;
      }
    },
    async approveDoc({ taskId }) {
      try {
        await this.completeTask({ taskId });
        this.removedMemoryTasks.push(taskId);
        this.cleanSelectedRows();
        this.onApproveDocSuccess();
      } catch (error) {
        this.onApproveDocFailure(error);
      }
    },

    selectedRow(rowIndex) {
      if (rowIndex === this.previewIndex && !this.togglePageViewer) return 'bg-selected';
    },
    async refetchData({ shouldClearSelection = true } = {}) {
      if (shouldClearSelection) {
        this.selectedDocIds = [];
        this.rowSelection = [];
      }

      await this.refetchDocuments();
      await new Promise((resolve) => setTimeout(resolve, 1500)); // make sure task is created along with document
      await this.refetchTasks();

      this.clearDeletedMemoryTasks();
      this.fixPreviewIndexOutOfBound();
    },

    clearDeletedMemoryTasks() {
      this.removedMemoryTasks.splice(0, this.removedMemoryTasks.length);
    },

    cleanSelectedRows() {
      this.selectedDocIds = [];
      this.rowSelection = [];
      this.fixPreviewIndexOutOfBound();
    },

    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, options.short) : '';
    },
    handleRowClick(row) {
      this.previewIndex = this.taskedDocuments.findIndex(({ doc }) => doc.id === row.doc.id);
      this.togglePageViewer = false;
      Object.values(this.pages).forEach(({ clear }) => clear());
    },

    getUserName(userId) {
      const user = this.users.find((u) => u.id === userId);
      if (!user) return 'Unknown user';
      return `${user.firstName} ${user.lastName}`;
    },
    getFileName(doc) {
      return doc.filePath.split('/').pop();
    },
    getFileNameIndex(doc) {
      const filename = this.getFileName(doc);
      return filename.split('_').pop().split('.').shift();
    },
    saveDoc(rowIndex) {
      const documentId = this.taskedDocuments[rowIndex].doc.id;
      const {
        doc: { isManual = false },
      } = this.taskedDocuments.find(({ doc }) => doc.id === documentId);

      const data = { ...this.tableInputState[rowIndex], isManual: !!isManual };
      const rejectNonValue = reject(anyPass([isNil, isEmpty]));
      updateDocument({
        id: documentId,
        data: rejectNonValue(data),
      }).catch((err) => {
        this.$notify.error({ title: err.error, message: err.reason });
      });
    },

    onApproveDocSuccess() {
      return this.$message.success(this.$i18n.tc('document.documentsOverview.classificationTasks.message.approved'));
    },
    onApproveDocFailure(err) {
      console.log('onApproveDocFailure');
      console.log(err);
      this.$message.error(`${err.error} - ${err.reason}`);
    },
    updatePreviewSelection() {
      // calculate index position of the first selected docs
      const idsMaps = new Set(this.selectedDocIds);
      const checkedDocsIndexes = this.taskedDocuments
        .map(({ doc }, index) => ({ id: doc.id, index }))
        .filter((doc) => idsMaps.has(doc.id))
        .map((item) => item.index);
      this.previewIndex = checkedDocsIndexes.length ? Math.min(...checkedDocsIndexes) : null;
    },
    fixPreviewIndexOutOfBound() {
      const docs = this.taskedDocuments;

      if (!docs[this.previewIndex]) {
        this.previewIndex = docs.length ? docs.length - 1 : 0;
      }
    },
    approveDocs() {
      this.updatePreviewSelection();
      const tasks = this.taskedDocuments.filter(({ doc }) => this.selectedDocIds.includes(doc.id));
      const selectedTaskIds = tasks.map(({ id }) => id);
      const promises = tasks.map(({ task }) => this.completeTask({ taskId: task.id }));
      Promise.all(promises)
        .then((approveDocResults) => {
          this.removedMemoryTasks.push(...selectedTaskIds);
          this.cleanSelectedRows();
          if (approveDocResults.length) {
            this.$message.success(
              this.$i18n.tc('document.documentsOverview.classificationTasks.message.approved', approveDocResults.length)
            );
          }
        })
        .catch((err) => {
          this.$message.error(`${err.error} - ${err.reason}`);
        });
    },
    mergeDocs() {
      this.updatePreviewSelection();
      mergeDocuments({ ids: this.selectedDocIds })
        .then(() => {
          this.$message.success(this.$i18n.t('document.documentsOverview.classificationTasks.message.merged'));
          return this.refetchData();
        })
        .catch((err) => {
          this.$message.error(`${err.error} - ${err.reason}`);
        });
    },
    removeDocs() {
      this.$confirm(this.$i18n.t('document.documentsOverview.classificationTasks.message.confirm')).then(() => {
        this.updatePreviewSelection();
        const tasks = this.taskedDocuments.filter(({ doc }) => this.selectedDocIds.includes(doc.id));
        const selectedTaskIds = tasks.map(({ id }) => id);
        const promises = this.selectedDocIds.map((id) => deleteDocument({ id }));
        Promise.all(promises)
          .then(() => {
            this.$message.success(
              this.$i18n.tc('document.documentsOverview.classificationTasks.message.removed', promises.length)
            );
            this.removedMemoryTasks.push(...selectedTaskIds);
            return this.cleanSelectedRows();
          })
          .catch((err) => {
            this.$message.error(`${err.error} - ${err.reason}`);
          });
      });
    },
    async derive(params) {
      try {
        await deriveDocuments(params);
        this.$message.success(this.$i18n.t('document.documentsOverview.classificationTasks.message.derived'));
        return this.refetchDocuments();
      } catch (err) {
        this.$message.error(`${err.error} - ${err.reason}`);
      }
    },
    splitDoc(doc) {
      splitDocument({ id: doc.id })
        .then(() => {
          this.$message.success(this.$i18n.t('document.documentsOverview.classificationTasks.message.splitted'));
          return this.refetchData();
        })
        .catch((err) => {
          this.$message.error(`${err.error} - ${err.reason}`);
        });
    },
    duplicateDoc(doc) {
      duplicateDocument({ id: doc.id })
        .then(() => {
          this.$message.success(this.$i18n.t('document.documentsOverview.classificationTasks.message.duplicated'));
          return this.refetchData();
        })
        .catch((err) => {
          this.$message.error(`${err.error} - ${err.reason}`);
        });
    },
    rotateDoc(doc) {
      rotateDocument({ id: doc.id })
        .then(() => {
          this.$message.success(this.$i18n.t('document.documentsOverview.classificationTasks.message.rotated'));
          return this.refetchData({ shouldClearSelection: false });
        })
        .catch((err) => {
          this.$message.error(`${err.error} - ${err.reason}`);
        });
    },
    downloadDoc(doc) {
      window.open(doc.filePathUrl, '_blank');
    },
    removeDoc(doc) {
      this.$confirm(this.$i18n.t('document.documentsOverview.classificationTasks.message.confirm')).then(() => {
        const { id: taskId } = this.taskedDocuments.find(({ doc: taskedDoc }) => taskedDoc.id === doc.id);
        deleteDocument({ id: doc.id })
          .then(() => {
            this.$message.success(this.$i18n.tc('document.documentsOverview.classificationTasks.message.removed'));
            this.fixPreviewIndexOutOfBound();
            return this.removedMemoryTasks.push(taskId);
          })
          .catch((err) => {
            this.$message.error(`${err.error} - ${err.reason}`);
          });
      });
    },

    clearPagesFocusedState() {
      Object.values(this.pages).forEach((page) => (page.isFocused = false));
    },

    async handlePageViewerClick({
      documentId,
      pageNumber,
      clearSelectedPages,
      approveCompleted,
      fixPageAfterApprove,
      getCurrentPage,
    }) {
      this.clearPagesFocusedState();
      // saving reference to clear selected page
      this.pages = {
        ...this.pages,
        ...{
          [documentId]: {
            clear: clearSelectedPages,
            approveCompleted,
            fixPageAfterApprove,
            getCurrentPage,
            isFocused: true,
          },
        },
      };

      const normalizePageNumber = pageNumber + 1;
      this.previewIndex = this.taskedDocuments.findIndex(({ doc }) => doc.id === documentId);

      const {
        doc: { pageCount },
      } = this.taskedDocuments.find(({ doc }) => doc.id === documentId);

      this.togglePageViewer = true;

      await this.$nextTick();
      this.pageViewerNumber = normalizePageNumber;
      this.nextPageViewerNumber = normalizePageNumber === pageCount ? 1 : normalizePageNumber;

      // calling clear selected page for anything but the current selected page
      Object.entries(this.pages).forEach(([docId, pageTableOperations]) => {
        if (docId !== documentId) pageTableOperations.clear();
      });
    },

    async handleApproveSingle({ documentId, pageNumber, enableApprove }) {
      const approvedPage = {
        documentPages: [
          {
            documentId,
            page: pageNumber,
          },
        ],
      };
      const {
        doc: { pageCount },
      } = this.taskedDocuments.find(({ doc }) => doc.id === documentId);
      const lastPage = pageCount === pageNumber + 1;

      await this.derive(approvedPage);
      await this.$nextTick();
      this.pageViewerNumber = this.nextPageViewerNumber;
      enableApprove();
      const { isFocused, fixPageAfterApprove, getCurrentPage, approveCompleted } = this.pages[documentId];
      approveCompleted();
      if (isFocused && !lastPage && getCurrentPage() > pageNumber) {
        const currentPageIndex = fixPageAfterApprove();
        this.pageViewerNumber = currentPageIndex + 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.scrollable-table {
  overflow-y: scroll;
  height: 70vh;
}

.action-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}

.actions-icon {
  height: 2rem;
  width: 2rem;
}

::v-deep .el-upload {
  width: 100%;
}
::v-deep .el-upload-dragger {
  width: 100%;
  height: 115px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

::v-deep .organize-tasks-table {
  tr:hover td.bg-selected {
    background: change-color($color: $surfaces-selected, $lightness: 90%) !important;
  }

  td:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
    vertical-align: top;
    padding-top: 0.5rem;
  }

  td:last-child:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
    padding: 0.5rem;
  }

  .el-form-item__content {
    line-height: 1;
  }
}

.remove-divider-margin {
  margin: 0;
}

.linkable {
  color: blue;
  background-color: transparent;
  text-decoration: underline;
}

.viewer-container {
  height: 80vh;
}

.no-file-selected {
  background-color: #fbfbfb;
  border: 1px solid #d2d4d7;
  color: #94989f;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
